<script setup lang="ts">
import type { Locales } from '~/composables/useLocale';

const route = useRoute();
const { t, locale } = useI18n();
const config = useRuntimeConfig();

const isMounted = ref(false);
const isCypress = useCookie<boolean>('cypress');

const canonical = computed(
  () =>
    `${getSiteUrl(config.public.projectEnvironment, 'host', locale.value as Locales)}${route.path}`,
);

useSchemaOrg([
  defineOrganization({
    name: 'Sex Play',
    logo: '/android-chrome-512x512.png',
  }),
  defineWebSite({
    name: 'Sex Play',
    datePublished: new Date(2022, 4, 27, 20).toISOString(),
  }),
  ...Array.from({ length: 3 }, (_, i) =>
    defineComment({
      text: t(`commentaries.${i}.text`),
      datePublished: new Date(2022, 5, 24, 20).toISOString(),
      author: {
        name: t(`commentaries.${i}.name`),
        image: t(`commentaries.${i}.alt`),
      },
    }),
  ),
]);

onMounted(() => {
  isMounted.value = true;
});
</script>

<template>
  <Html
    :lang="locale"
    :class="{ disableAnimation: isCypress, mounted: isMounted }"
  />
  <Head>
    <Title>{{ t('meta.basic.title') }}</Title>
    <Meta name="description" :content="t('meta.basic.description')" />
    <Meta name="keywords" :content="t('meta.basic.keywords')" />

    <Meta property="og:site_name" content="Sex Play" />
    <Meta property="og:title" :content="t('meta.basic.title')" />
    <Meta property="og:description" :content="t('meta.basic.description')" />
    <Meta property="og:image" content="https://sex-play.ru/og_image.jpeg" />
    <Meta property="og:image:width" content="1200" />
    <Meta property="og:image:height" content="630" />
    <Meta property="og:type" content="website" />
    <Meta name="twitter:card" content="summary_large_image" />

    <Link rel="canonical" :href="canonical" />
    <Link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <Link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <Link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <Link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000" />
    <Meta name="msapplication-TileColor" content="#000000" />
    <Meta name="theme-color" content="#20262e" />
  </Head>
  <Body class="p-dark sex-play-theme" />

  <VitePwaManifest />
  <BaseI18nSetup />

  <ClientOnly>
    <Toast position="bottom-center" />
    <ConfirmDialog />
  </ClientOnly>

  <NuxtPage />

  <div id="captcha-container" :data-hl="locale" />
</template>

<i18n src="~/locales/meta.yaml"></i18n>
<i18n src="~/locales/commentaries.yaml"></i18n>

<style lang="scss">
@import 'assets/styles/base.scss';
@import 'assets/styles/components.scss';
@import 'assets/styles/animations.scss';

.disableAnimation * {
  animation-duration: 0s !important;
  transition-duration: 0s !important;
}
</style>
