import * as Sentry from '@sentry/vue';
import { withScope } from '@sentry/vue';

export default defineNuxtPlugin((nuxtApp) => {
  if (import.meta.dev) return;

  const config = useRuntimeConfig();
  const router = useRouter();

  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    environment: process.env.NODE_ENV,
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    dsn: config.public.sentryDns as string,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
        routeLabel: 'path',
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [config.public.siteUrl],
      }),
    ],
    tracePropagationTargets: [
      'localhost',
      /^\//,
      'sex-play.ru',
      'en.sex-play.ru',
    ],
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.5,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1,
  });

  nuxtApp.vueApp.config.errorHandler = (err, context) => {
    withScope((scope) => {
      scope.setExtra('context', context);
      Sentry.captureException(err);
    });
  };

  nuxtApp.hook('app:error', (err) => {
    Sentry.captureException(err);
  });
});
