import revive_payload_client_wUKZ9o2R6p from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YsqFPN1Dea from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Kl3NbxAvPT from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_nJuO64GSNw from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.4_sass@1.79._ijgjy3sfkf2vagmf2uuj3xfxkq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_Y8hEGaKY2j from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_eAF1aSuaO4 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_6jBax349NI from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_tdvSG6M6Jb from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SXq5ZjOVDj from "/_base/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.2_vue@3.5.11_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_40jDBZCCeg from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_ioredis@5.4.1_magicast@0.3.5_rollup@4.24_pn5tgxhj6te6hit7mrdv6a4bre/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_H29DCpqsca from "/_base/node_modules/.pnpm/nuxt-strict-fetch@0.7.2_magicast@0.3.5_rollup@4.24.0/node_modules/nuxt-strict-fetch/dist/runtime/plugin.js";
import switch_locale_path_ssr_3qVv1XC3dC from "/_base/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_3Xu382CmGd from "/_base/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_vg5x9dYieD from "/_base/node_modules/.pnpm/@primevue+nuxt-module@4.0.7_@babel+parser@7.25.7_magicast@0.3.5_rollup@4.24.0_vue@3.5.11_typescript@5.6.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_iXDwUwlQML from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.4_sass@1.79.4__ndxrrvtb6cgm7wg3wje36cnjea/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import request_uzz8eHlqp7 from "/_base/plugins/request.ts";
import autoAnimate_4HQGapz9xs from "/app/plugins/autoAnimate.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import showOnVisible_MqBxBtg92A from "/app/plugins/showOnVisible.ts";
export default [
  revive_payload_client_wUKZ9o2R6p,
  unhead_YsqFPN1Dea,
  router_Kl3NbxAvPT,
  _0_siteConfig_nJuO64GSNw,
  payload_client_Y8hEGaKY2j,
  navigation_repaint_client_eAF1aSuaO4,
  check_outdated_build_client_6jBax349NI,
  chunk_reload_client_tdvSG6M6Jb,
  plugin_vue3_SXq5ZjOVDj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_40jDBZCCeg,
  plugin_H29DCpqsca,
  switch_locale_path_ssr_3qVv1XC3dC,
  i18n_3Xu382CmGd,
  primevue_plugin_egKpok8Auk,
  plugin_client_vg5x9dYieD,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_iXDwUwlQML,
  request_uzz8eHlqp7,
  autoAnimate_4HQGapz9xs,
  sentry_client_shVUlIjFLk,
  showOnVisible_MqBxBtg92A
]