<script lang="ts" setup>
import type { H3Error } from 'h3';

const props = defineProps<{
  error: H3Error;
}>();

const { t } = useI18n();
const router = useRouter();

const isActionsEnabled = computed(() => props.error.statusCode !== 403);
</script>

<template>
  <div
    class="bg-surface-800 px-4 py-8 md:px-6 lg:px-8 flex flex-col items-center justify-center"
    style="height: 100svh"
  >
    <div
      class="text-center w-full"
      style="
        background: radial-gradient(
          50% 109138% at 50% 50%,
          rgba(233, 30, 99, 0.1) 0%,
          rgba(254, 244, 247, 0) 100%
        );
      "
    >
      <span class="bg-white font-bold text-2xl inline-block px-3 text-pink-500">
        {{ props.error.statusCode }}
      </span>
    </div>
    <div class="mt-8 mb-10 font-bold text-5xl text-900 text-center">
      {{ props.error.message }}
    </div>
    <div v-if="isActionsEnabled" class="text-center">
      <Button
        @click="router.back"
        text
        class="mr-2"
        type="button"
        size="large"
        icon="pi pi-arrow-left"
        :label="t('back')"
      />
      <NuxtLink to="/">
        <Button icon="pi pi-home" :label="t('home')" size="large" />
      </NuxtLink>
    </div>
  </div>
</template>

<i18n>
en:
  back: Go back
  home: Go to main

ru:
  back: Назад
  home: На главную
</i18n>
